import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Reminders from './Reminders';
import { partition} from 'lodash';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ReminderTab(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const remindersArray =  partition(props.data, function(reminder) { return reminder.reminder_sent === true; });
    const pastReminders = remindersArray[0];
    const futureReminders = remindersArray[1];

    return (
        <div className='table-container'>
            <Box sx={{ width: '100%', paddingTop:"40px" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                        <Tab label="Future Reminders" {...a11yProps(0)} />
                        <Tab label="Past Reminders" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Reminders data={futureReminders} deleteItem={props.deleteItem} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Reminders data={pastReminders} deleteItem={props.deleteItem} isPast={true} />
                </TabPanel>
            </Box>
        </div>
    );
}
