
import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ReminderTab from './ReminderTab';
import Form from './Form';
import { useAuth0 } from "@auth0/auth0-react";
import Loading from './Loading';


export default function Main() {

    const [loading, setLoading] = React.useState(false);
    const { user, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
    const [link, setLink] = React.useState("")
    const [time, setTime] = React.useState("")
    const [userData, setUserData] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = React.useState('success')
    const [showSpinner,setShowSpinner] = React.useState(false);


    React.useEffect(async () => {
        setShowSpinner(true);
        const accessToken = await getAccessTokenSilently();
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'accept': 'application/json', 'Authorization': `Bearer ${accessToken}` }
        };
        isAuthenticated && fetch(`${process.env.REACT_APP_GET_USER_BY_EMAIL_URL}=${user.email}`, requestOptions).then((response) => response.json())
            .then(function (data) {
                if (data) {
                    setUserData(data['reminders'])
                }
                setShowSpinner(false);
            })
    }, [isAuthenticated])

    async function sendData(e) {
        e.preventDefault();
        setLoading(true);
        let currentDateTime = moment()
        currentDateTime.add(time, 'hours')

        const reminderTime = currentDateTime.format("DD-MMM-YYYY HH:mm:ss").toLocaleString() + " - " + currentDateTime.add(1,'hours').format("DD-MMM-YYYY HH:mm:ss").toLocaleString()
        userData.push({
            'id': uuidv4(),
            'detail': link,
            'reminder_time': reminderTime,
            'reminder_sent':false
        })
        setLink('')
        setTime('')
        //MAKE Api
        const accessToken = await getAccessTokenSilently();
        let requestPayload = {};
        requestPayload['email'] = user.email
        requestPayload['reminders'] = userData


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'accept': 'application/json', 'Authorization': `Bearer ${accessToken}` },
            body: JSON.stringify(requestPayload)
        };

        fetch(`${process.env.REACT_APP_POST_USER_URL}`, requestOptions)
            .then(response => {
                if (response.status === 200) {
                    openSnackbar();
                    setLoading(false);
                }
                else {
                    openDeleteSnackbar();
                    setLoading(false);
                }
            })
            .then(data => {
            });

    }


    async function deleteItem(linkId) {
        const filteredData = userData.filter((item) => item.id !== linkId);
        setUserData(filteredData);
        //Make Delete API call

        const accessToken = await getAccessTokenSilently();
        let requestPayload = {};
        requestPayload['email'] = user.email
        requestPayload['reminder_id'] = linkId


        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'accept': 'application/json', 'Authorization': `Bearer ${accessToken}` },
            body: JSON.stringify(requestPayload)
        };

        fetch(`${process.env.REACT_APP_DELETE_USER_URL}`, requestOptions)
            .then(response => {
                if (response.status === 200) {
                    openDeleteSnackbar();
                }
                else {
                    openDeleteSnackbar();
                }
            })
            .then(data => {
            });

    }

    const handleChange = (event) => {
        if (event.target.name === 'time') {
            setTime(event.target.value);
        } else {
            setLink(event.target.value)
        }

    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });


    const openSnackbar = () => {
        setSnackbarSeverity("success");
        setOpen(true);
    };

    const openDeleteSnackbar = () => {
        setSnackbarSeverity("warning");
        setOpen(true);
    }

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        showSpinner ? <Loading/> :
        <div className='main-container'>

            <div className='form-button-container'>
                <Form handleChange={handleChange} link={link} time={time} />
                <div className='submit-btn'>
                    <LoadingButton
                        onClick={sendData}
                        endIcon={<SendIcon />}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                        disabled={!time || !link}
                    >
                        Add Reminder
                    </LoadingButton>
                </div>
            </div>

            <ReminderTab data={userData} deleteItem={deleteItem} />
            <Snackbar open={open} autoHideDuration={3000} onClose={closeSnackbar}
                anchorOrigin={{ 'vertical': 'bottom', 'horizontal': 'center' }}
            >
                <Alert onClose={closeSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarSeverity === "success" ? "Added reminder" : "Deleted Reminder"}
                </Alert>
            </Snackbar>
        </div>
    )
}