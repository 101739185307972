import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';

export default function About() {


    return (
        <div className="about">
            <Box sx={{ width: '100%', maxWidth: 500,  textAlign: "justify",
  textJustify: "inter-word" }}>
                <Typography variant="subtitle1" gutterBottom component="div">
                    remindMe is a web application which you can use to set reminders for yourself.
                    Enter a reminder and the time duration, and you'll receive a email during that
                    time reminding you of the task.
                </Typography>
                {/* <Typography variant="subtitle2" gutterBottom component="div">
                    subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                    blanditiis tenetur
                </Typography> */}
                <Typography variant="body1" gutterBottom>
                    <b>Some uses:-</b>
                    <List >
                        <ListItem>
                            - Remind yourself about a blog you have to read
                        </ListItem>
                        <ListItem>
                            - Remind yourself about a pending task
                        </ListItem>
                    </List>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <b>Upcoming Features:-</b>
                    <List >
                        <ListItem>
                            - Custom Time and Data selection
                        </ListItem>
                        <ListItem>
                            - Support for multiple time zones
                        </ListItem>
                    </List>
                </Typography>
                
                <Typography variant="h4" gutterBottom component="div">
                        Checkout my other <a href='https://www.sandyjswl.com/project/' target='_blank'>projects</a>
                </Typography>

            </Box>

        </div>
    )
}